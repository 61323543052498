.popup-enter {
  opacity: 0;
}

.popup-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
  top: 0;
}

.popup-exit {
  opacity: 1;
  top:0;
}

.popup-exit-active {
  opacity: 0;
  transition: all 300ms ease-in;
}

.openPinDialog-enter {
  opacity: 0 ;
  /* z-index: -1; */
  margin-top: -62px;
}

.openPinDialog-enter-active {
  opacity: 1;
  transition: all 300ms linear;
  margin-top: 0px;
}

.openPinDialog-exit {
  opacity: 1;
  /* z-index: -1; */
  margin-top: 0px;
}

.openPinDialog-exit-active {
  opacity: 0;
  /* z-index: -1; */
  margin-top: -62px;
  transition: all 300ms linear;
}


.locationBox-enter {
  opacity: 0;
  margin-top: -50px;
}

.locationBox-enter-active {
  opacity: 1;
  transition: all 300ms linear;
  margin-top: 0px;
}

.locationBox-exit {
  opacity: 1;
  margin-top: 0px;
}

.locationBox-exit-active {
  opacity: 0;
  margin-top: -50px;
  transition: all 300ms linear;
}

