/* loader css */
.loaderContainer {
  height: 100%;
  width: 100%;
  position: absolute;
}
.loaderContainerFix {
  height: 100%;
  width: 100%;
  position: relative;
  min-width: 300px;
  min-height: 80px;
}
.loaderContainerFF {
  height: 100vh;
  width: 100vw;
  position: relative;
}
.loader {
  border: 12px solid #f3f3f3;
  border-top: 11px solid black;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  animation: rotate 1s linear infinite;
  /* background-color: black; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadering {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 20px;
  height: 20px;
  margin: auto;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
